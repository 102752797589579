<template>
  <v-container class="card-con">
    <v-card max-width="480" width="100%" class="ma-auto">
      <v-card-text class="d-flex flex-column justify-center text-center">
        <div class="d-flex justify-center">
          <v-img
            :src="require('@/assets/energy/WhiteMouseEnergy.png')"
            max-width="300px"
            contain
          ></v-img>
        </div>
        <div class="d-flex justify-center">
          <SiteLogo />
        </div>
        <div class="mb-3 mt-3">
          <h2 class="mb-3">{{ status }}</h2>
          <h1>{{ progression }}%</h1>
        </div>
        <v-progress-linear
          color="cyan"
          indeterminate
          rounded
          height="10"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-container>
</template>
  
  <script>
import { mapState } from "vuex";
export default {
  components: {
    SiteLogo: () =>
      import(
        /* webpackChunkName: "component-site-logo" */ "@/components/SiteLogo.vue"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    status: "Login...",
    progression: 0,
    timing: 0,
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
  }),
  async created() {
    let tempSettings;
    if (this.settings) {
      tempSettings = this.$_.cloneDeep(this.settings);
      tempSettings["login_at"] = this.$moment().format();
    } else {
      tempSettings = {
        login_at: this.$moment().format(),
      };
    }
    this.$store.commit("updateSettings", tempSettings);
    const urlParams = new URLSearchParams(window.location.search);
    this.progression = urlParams.get("progression") * 1 ?? 0;
    if (this.progression == 0) {
      await this.login();
    }
    this.timing = this.$_.clone(this.progression);
    //
    var that = this;
    var interval = setInterval(() => {
      that.timing += 1;
      if (that.progression < 100) that.progression += 1;
      if (that.timing == 60) that.refreshPage();
      if (that.timing >= 60 && that.timing < 100)
        this.status = "Refreshed page...";
      if (that.timing == 100) this.status = "Completed.";
      if (that.timing == 120) this.status = "Redirecting...";
      if (that.timing >= 130) {
        clearInterval(interval);
        that.$router.push({ name: "PageExploreTrailer" });
      }
    }, 60);
  },
  mounted() {
    //
  },
  methods: {
    async login() {
      this.api.url =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/player/relogin";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
        console.error(e);
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.$store.commit("updateAuth", resp);
      };
      this.api.params = {
        groupCode: this.auth.Group.code,
      };
      await this.$api.fetch(this.api);
    },
    resetSkin() {
      this.status = "Reset skins...";
      this.$store.dispatch("resetSkin");
    },
    resetApparel() {
      this.status = "Reset apparels...";
      this.$store.dispatch("resetApparel");
    },
    refreshPage() {
      // window.location.reload(true);
      const newUrl = `${window.location.origin}${window.location.pathname}?progression=61`;
      window.location.href = newUrl;
    },
  },
};
</script>

<style scoped>
.card-con{
  display: flex;
  min-width: 100vw;
  height: 100vh !important;
}
</style>